import React, { useState, useEffect } from "react";
import axios from "axios";
import NET from "../Url/url";
import { useIntl } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/news/style.css";
import "../../assets/js/script";
import { FormattedMessage } from "react-intl";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

function Partner({ path }) {
  const [caron_partner, setcaron_partner] = useState([]);

  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_partner`)
      .then((res) => setcaron_partner(res.data));
  }, []);

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/regions`)
      .then((res) => setRegions(res.data));
  }, []);

  console.log(regions);
  const intl = useIntl();

  const partnersByLocation = caron_partner.reduce((acc, partner) => {
    if (!acc[partner.location]) {
      acc[partner.location] = [];
    }
    acc[partner.location].push(partner);
    return acc;
  }, {});
  const uniqueLocations = Object.keys(partnersByLocation);

  const filteredRegions = regions.filter((item) => {
    let neww = uniqueLocations.find((x) => x === item.region_en);
    return neww;
  });

  return (
    <>
      <Container>
        <Row>
          <Col xl={12}>
            <h3 className="news_title">
              <FormattedMessage id={"nav_sixth"} />
            </h3>
          </Col>
        </Row>
      </Container>
      <Container className={"section_for_partners_accardion"}>
        <Row>
          <Col xl={12} className={"section_for_partners_accardion"}>
            <Tabs>
              <TabList>
                {filteredRegions.map((location, index) => (
                  <Tab key={index}>{location[`region_${intl.locale}`]}</Tab>
                ))}
              </TabList>
              {uniqueLocations.map((location, index) => (
                <TabPanel key={index}>
                  <Container className="border py-5">
                    <Row>
                      {path == "home"
                        ? partnersByLocation[location]
                            .splice(0, 3)
                            .map((partner, partnerIndex) => (
                              <Col
                                xl={{ span: 4, offset: 0 }}
                                lg={{ span: 3, offset: 0 }}
                                md={{ span: 6, offset: 0 }}
                                sm={{ span: 6, offset: 3 }}
                                xs={{ span: 8, offset: 2 }}
                                key={partnerIndex}
                              >
                                <div className={"partners-parts"}>
                                  <div className="for_partners_img">
                                    <img
                                      src={`https://admin.photonauto.ru/public/image/${partner.partner_image}`}
                                      className={"img-fluid"}
                                      alt={partner.partner_image}
                                    />
                                  </div>
                                  <div className="for_partners_name">
                                    <p>{partner[`title_${intl.locale}`]}</p>
                                  </div>
                                  <div className="for_partners_name second">
                                    <p>{partner[`address_${intl.locale}`]}</p>
                                  </div>
                                </div>
                              </Col>
                            ))
                        : partnersByLocation[location].map(
                            (partner, partnerIndex) => (
                              <Col
                                xl={{ span: 4, offset: 0 }}
                                lg={{ span: 3, offset: 0 }}
                                md={{ span: 6, offset: 0 }}
                                sm={{ span: 6, offset: 3 }}
                                xs={{ span: 8, offset: 2 }}
                                key={partnerIndex}
                              >
                                <div className={"partners-parts"}>
                                  <div className="for_partners_img">
                                    <img
                                      src={`https://admin.photonauto.ru/public/image/${partner.partner_image}`}
                                      className={"img-fluid"}
                                      alt={partner.partner_image}
                                    />
                                  </div>
                                  <div className="for_partners_name">
                                    <p>{partner[`title_${intl.locale}`]}</p>
                                  </div>
                                  <div className="for_partners_name second">
                                    <p>{partner[`address_${intl.locale}`]}</p>
                                  </div>
                                </div>
                              </Col>
                            )
                          )}
                    </Row>
                  </Container>
                </TabPanel>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Partner;
