import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NET from "../Url/url";
import axios from "axios";
import { useIntl } from "react-intl";
import Gallery from "./Gallery";
import "../../assets/css/home/style.css";

export default function LampSingle() {
  const intl = useIntl();
  const { slug } = useParams();

  const [lamps, setLamps] = useState([]);
  const [lampsImages, setLampsImages] = useState([]);

  useEffect(() => {
    axios.get(`https://admin.photonauto.ru/api/lamps`).then((res) => {
      setLamps(res.data);
    });
    axios.get(`https://admin.photonauto.ru/api/lamps_images`).then((res) => {
      setLampsImages(res.data);
    });
  }, []);

  const lamp = lamps.find((items) => items.slug === slug);

  // Filter images related to the current lamp
  const filteredImages = lamp
    ? lampsImages.filter((image) => image.lamps_id === lamp.id)
    : [];

  return (
    <Container id="Products">
      <Row>
        <Col xl={6}>
          {lamp && (
            <div>
              <img
                src={`https://admin.photonauto.ru/public/uploads/${lamp.cover_image}`}
                className="img-fluid"
                alt={lamp.title_ru}
              />
            </div>
          )}
        </Col>
        <Col xl={6}>
          {lamp && (
            <div className="text_section">
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: lamp[`text_${intl.locale}`],
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className="my-5">
        <Gallery data={filteredImages} />
      </Row>
    </Container>
  );
}
