import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import HomeSlider from "./HomeSlider";
import { Col, Container, Row } from "react-bootstrap";
import CaronLogo from "../../assets/img/Home/nor.jpg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "../../assets/css/home/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NET from "../Url/url";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useIntl } from "react-intl";
import Partner from "./partner";

function Home() {
  const [lamps, setLamps] = useState([]);

  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/lamps`)
      .then((res) => setLamps(res.data));
  }, []);

  const [home_carousel_images, sethome_carousel_images] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/home_carousel_images`)
      .then((res) => sethome_carousel_images(res.data));
  }, []);

  const [caron_about, setcaron_about] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_about`)
      .then((res) => setcaron_about(res.data));
  }, []);

  const [caron_news, setcaron_news] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_news`)
      .then((res) => setcaron_news(res.data));
  }, []);
  const sortedNews = useMemo(() => {
    if (!caron_news) return [];
    return caron_news
      .slice()
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [caron_news]);

  const intl = useIntl();

  return (
    <>
      <HomeSlider />

      {/* <Container fluid id={"section_for_carousel"}>
        <Container>
          <Row>
            <Col xl={12} className="py-5">
              {home_carousel_images &&
                home_carousel_images.slice(0, 1).map((item, index) => {
                  return (
                    <img
                      src={`https://admin.photonauto.ru/public/image/${item.carousel_images}`}
                      alt=""
                      className="img-fluid"
                      key={index}
                    />
                  );
                })}
            </Col>
          </Row>
        </Container>
      </Container> */}
      <Container fluid id={"representation_section"} className="">
        <Container>
          <Row>
            {lamps &&
              lamps.map((item, index) => {
                return (
                  <Col
                    xl={{ span: 3, offset: 0 }}
                    lg={{ span: 3, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 3 }}
                    xs={{ span: 8, offset: 2 }}
                    key={index}
                    className="lamps"
                  >
                    <div>
                      <img
                        src={`https://admin.photonauto.ru/public/uploads/${item.cover_image}`}
                        className={"img-fluid"}
                        alt={item.title_ru}
                      />
                    </div>
                    <div className="lamps-small">
                      <p className="title">{item[`title_${intl.locale}`]}</p>
                      <Link to={`/lamp/${item.slug}`} className="">
                        <FormattedMessage id={"see_more"} />
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </Container>

      {caron_about &&
        caron_about.map((items, index) => {
          if (items.location == "home_page")
            return (
              <div id={"home-section-about_about"}>
                <Container fluid className={"m-0 about_height_container"}>
                  <Row key={index}>
                    <Col
                      xl={{ span: 3, offset: 2 }}
                      lg={{ span: 3, offset: 2 }}
                      md={{ span: 3, offset: 2 }}
                      sm={{ span: 4, offset: 3 }}
                      xs={{ span: 6, offset: 3 }}
                      className={"left_section"}
                    >
                      <img
                        src={`https://admin.photonauto.ru/public/image/${items.about_images}`}
                        className={"img-fluid w-100"}
                        alt={items.title}
                      />
                    </Col>
                    <Col
                      xl={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      md={{ span: 5, offset: 1 }}
                      sm={{ span: 6, offset: 3 }}
                      xs={{ span: 10, offset: 1 }}
                      className={"right_section"}
                    >
                      <h3>
                        <FormattedMessage id={"nav_second"} />
                      </h3>
                      <p>{items[`description_${intl.locale}`]}</p>
                      <Link to={"/about"} className={"prev-link"}>
                        <FormattedMessage id={"see_more"} />
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
        })}

      <Container fluid id={"partners_section"}>
        <Container>
          <Row>
            <Col xl={12}>
              <h3 className={"big-title"}>
                <FormattedMessage id={"nav_sixth"} />
              </h3>
            </Col>
          </Row>
          <Partner path="home" />

          <Row>
            <Col xl={12} className={"for-link"}>
              <Link to={"/partner"} className={"prev-link"}>
                <FormattedMessage id={"see_more"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id={"news_section"}>
        <Container>
          <Row>
            <Col xl={12} className={"big-title"}>
              <FormattedMessage id={"nav_fifth"} />
            </Col>
          </Row>
          <Row className={"row_for_news"}>
            {sortedNews &&
              sortedNews.slice(0, 3).map((itemm, index) => {
                return (
                  <Col
                    xl={{ span: 4, offset: 0 }}
                    lg={{ span: 4, offset: 0 }}
                    md={{ span: 6, offset: 3 }}
                    sm={{ span: 8, offset: 2 }}
                    xs={{ span: 10, offset: 1 }}
                    key={index}
                  >
                    <div className={"news-img-section"}>
                      <div className="news-img-img">
                        <img
                          src={`https://admin.photonauto.ru/public/image/${itemm.news_images}`}
                          className={"img-fluid"}
                          alt=""
                        />
                      </div>
                      <div className="news-img-text">
                        <h3>{itemm[`title_${intl.locale}`]}</h3>
                      </div>

                      <div className={"prev-link-div"}>
                        <Link
                          to={`/NewsList/${itemm.id}`}
                          className={"prev-link"}
                        >
                          <FormattedMessage id={"see_more"} />
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col xl={12} className={"for-link"}>
              <Link to={"/news"} className={"prev-link"}>
                <FormattedMessage id={"see_more"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default Home;
