import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import "../../assets/css/news/style.css";
import PartnerImg from "../../assets/img/BecomePartner/partner.jpg";
import Modal from "react-modal";
import emailjs from "emailjs-com";
import { CSSTransition } from "react-transition-group";

function sendEmailPartner(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_4uwaf5j",
      "template_c86cu5n",
      e.target,
      "user_SEjLQG67dF8MSaH7z3dwW"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
}

export default function Work() {
  const intl = useIntl();

  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const setModalIsOpen1ToTrue = () => {
    setModalIsOpen1(true);
  };

  const setModalIsOpen1ToFalse = () => {
    setModalIsOpen1(false);
  };
  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,.6)",
    },
  };
  return (
    <>
      <Container className={"section_for_become_partner1"}>
        <Row className={"row_for_become_partners"}>
          <div className={"section_for_partner_modal"}>
            <div className={"partner_modal_btn"}>
              <Button onClick={setModalIsOpen1ToTrue}>
                <FormattedMessage id={"become_partner"} />
              </Button>
              <CSSTransition
                in={modalIsOpen1}
                timeout={300}
                classNames="dialog"
              >
                <Modal
                  closeTimeoutMS={500}
                  isOpen={modalIsOpen1}
                  style={modalStyles}
                >
                  <div className={"div_for_btn_close"}>
                    <button
                      className={"close_btn"}
                      onClick={setModalIsOpen1ToFalse}
                      style={{
                        float: "right",
                        background: "transparent",
                        border: "none",
                        color: "#ffae03",
                      }}
                    >
                      X
                    </button>
                  </div>
                  <Container className={"mt-3"}>
                    <Row className={"row_for_modal_title"}>
                      <Col xl={{ span: 10, offset: 1 }} className={"title"}>
                        <FormattedMessage id={"form_value"} />
                        <a href="mailto:group.caron@gmail.com">
                          group.caron@gmail.com
                        </a>
                      </Col>
                    </Row>
                    <Row className={"row_for_form"}>
                      <Col xl={{ span: 10, offset: 1 }}>
                        <Form onSubmit={sendEmailPartner}>
                          <Form.Group>
                            <Form.Label for="name_lastname">
                              <FormattedMessage id={"name_lastname"} />
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id={"name_lastname"}
                              required
                              placeholder={intl.formatMessage({
                                id: "name_lastname",
                              })}
                              name={"partner_name_lastname"}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label for="for_phone">
                              <FormattedMessage id={"label_tel"} />
                            </Form.Label>
                            <Form.Control
                              type="number"
                              id={"for_phone"}
                              required
                              placeholder={"+374 00 00 00 00"}
                              name={"partner_number"}
                            />
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label for="for_email">
                              <FormattedMessage id={"label_email"} />
                            </Form.Label>
                            <Form.Control
                              type="email"
                              id={"for_email"}
                              required
                              placeholder={"example@mail.ru"}
                              name={"partner_email"}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label for={"for_education"}>
                              <FormattedMessage id="label_education" />
                            </Form.Label>
                            <Form.Control
                              as="select"
                              id={"for_education"}
                              name={"education"}
                              required
                            >
                              <option value="Միջնակարգ">
                                {intl.formatMessage({
                                  id: "label_mijnakarg",
                                })}
                              </option>
                              <option value="Բակալավր">
                                {intl.formatMessage({
                                  id: "label_bak",
                                })}
                              </option>
                              <option value="Մագիստրոս">
                                {intl.formatMessage({
                                  id: "label_mag",
                                })}
                              </option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label for={"for_education"}>
                              <FormattedMessage id="label_hastiq" />
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id={"for_email"}
                              required
                              placeholder={intl.formatMessage({
                                id: "label_hastiq",
                              })}
                              name={"worker"}
                            />
                          </Form.Group>
                          <div className={"partner_modal_btn"}>
                            <Button type={"submit"}>
                              <FormattedMessage id={"form_submit"} />
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </CSSTransition>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
