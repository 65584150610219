import React from "react";
import {FormattedMessage} from "react-intl";


function Online() {
    return(
        <>
            <div className="for_online">
                <a href="https://shop.caron.am" target="_blank">
                    <FormattedMessage id={'online'} />
                </a>
            </div>
        </>
    )
}
export default Online