import React, {useContext} from 'react';
import {Context} from "../Multilang/Wrapper";
import ArmFlag  from '../../assets/img/Header/amFlag.png';
import RuFlag  from '../../assets/img/Header/ruFlag.png';
import EnFlag  from '../../assets/img/Header/enFlag.png';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "../../assets/css/header/style.css";
function Lang (){
    const context = useContext(Context);
    const sectionStyle = {
        width: "100%",
        height: "400px",
        backgroundImage: "url(" + { ArmFlag } + ")",
        backgroundSize:'cover',
        backgroundPosition:'center',
        zIndex:'0'
    };
    return (
        <>
            <div>
                <Select
                    value = {context.locale} onChange={context.selectLanguage}

                >
                    <MenuItem value={'am'} id={'aaa'}  style={{zIndex:'999999999999999 !important'}}>
                        <img src={ArmFlag} alt=""/>
                    </MenuItem>
                    <MenuItem value={'ru'}>
                        <img src={RuFlag} alt=""/>
                    </MenuItem>
                    <MenuItem value={'en'}>
                        <img src={EnFlag} alt=""/>
                    </MenuItem>
                </Select>
            </div>

        </>
    )
}
export default Lang;