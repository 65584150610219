import { useState } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import "../../../node_modules/yet-another-react-lightbox/dist/styles.css";
import NET from "../Url/url";

export default function Gallery({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="grid-container">
        {data.map((e, i) => {
          return (
            <img
              src={`https://admin.photonauto.ru/public/uploads/${e.gallery_images}`}
              alt="Images"
              onClick={() => setOpen(true)}
              className="img-fluid"
              key={i}
            />
          );
        })}
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={data.map((url) => ({
            src: `https://admin.photonauto.ru/public/uploads/${url.gallery_images}`,
          }))}
        />
      </div>
    </>
  );
}
