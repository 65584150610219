import React, { useState, useEffect } from "react";

import axios from "axios";
import NET from "../Url/url";
import { useIntl } from "react-intl";

import { Container, Row, Col } from "react-bootstrap";

import "../../assets/css/home/style.css";

import { FormattedMessage } from "react-intl";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaTelegram } from "react-icons/fa";
import { SlSocialVkontakte } from "react-icons/sl";

import TelImg from "../../assets/img/Footer/Phone.png";
import GmailImg from "../../assets/img/Footer/gmail.png";
import AddressImg from "../../assets/img/Footer/Address.png";

import Work from "./work";
function Footer() {
  const [caron_data, setcaron_data] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_data`)
      .then((res) => setcaron_data(res.data));
  }, []);

  const [caron_about, setcaron_about] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_about`)
      .then((res) => setcaron_about(res.data));
  }, []);
  const intl = useIntl();

  return (
    <>
      <Container fluid className={"section_for_footer"}>
        <Container>
          <Row>
            {caron_about &&
              caron_about.map((items, index) => {
                if (items.location == "about_footer")
                  return (
                    <Col xl={8} lg={8} key={index}>
                      <img
                        src={`https://admin.photonauto.ru/public/image/${items.about_images}`}
                        className={"img-fluid w-100"}
                      />
                    </Col>
                  );
              })}

            <Col xl={4} lg={4} className={"right-section"}>
              <div>
                <Work />
              </div>
              <div className={"right_section_title"}>
                <FormattedMessage id={"nav_eight"} />
              </div>
              <div className={"right_section_title"}>
                {caron_data &&
                  caron_data.slice(0, 1).map((item, index) => {
                    return (
                      <ul>
                        <li>
                          <img src={TelImg} alt="" />
                          <a href={`tel:${item.tel}`}>{item.tel}</a>
                        </li>
                        <li>
                          <img src={GmailImg} alt="" />
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </li>
                        <li>
                          <img src={AddressImg} alt="" />
                          <a href="#">{item[`address_${intl.locale}`]}</a>
                        </li>
                        <li>
                          {item.face_link && (
                            <a
                              href={item.face_link}
                              target={"_blank"}
                              className={"icon_social"}
                            >
                              <FaFacebookF />
                            </a>
                          )}
                          {item.insta_link && (
                            <a
                              href={item.insta_link}
                              target={"_blank"}
                              className={"icon_social"}
                            >
                              <FaInstagram />
                            </a>
                          )}
                          {item.youtube_link && (
                            <a
                              href={item.youtube_link}
                              target="_blank"
                              className={"icon_social"}
                            >
                              <FaYoutube />
                            </a>
                          )}
                          {item.telegram_link && (
                            <a
                              href={item.telegram_link}
                              target="_blank"
                              className={"icon_social"}
                            >
                              <FaTelegram />
                            </a>
                          )}
                          {item.vk_link && (
                            <a
                              href={item.vk_link}
                              target="_blank"
                              className={"icon_social"}
                            >
                              <SlSocialVkontakte />
                            </a>
                          )}
                        </li>
                      </ul>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default Footer;
