import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import NET from "../Url/url";
import { useIntl } from "react-intl";

import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/news/style.css";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
function News() {
  const [caron_news, setcaron_news] = useState([]);
  useEffect(() => {
    axios
      .get(`https://admin.photonauto.ru/api/caron_news`)
      .then((res) => setcaron_news(res.data));
  }, []);

  const sortedNews = useMemo(() => {
    if (!caron_news) return [];
    return caron_news
      .slice()
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [caron_news]);
  const intl = useIntl();
  return (
    <>
      <Container className={"news_section_big"}>
        <Row>
          <Col>
            <h3 className={"news_title"}>
              <FormattedMessage id={"nav_fifth"} />
            </h3>
          </Col>
        </Row>
        <Row>
          {sortedNews &&
            sortedNews.map((item, index) => {
              return (
                <Col
                  key={index}
                  xl={{ span: 3, offset: 0 }}
                  lg={{ span: 3, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  sm={{ span: 6, offset: 0 }}
                  xs={{ span: 8, offset: 2 }}
                  className="mb-3"
                >
                  <div className="news_section_small">
                    <div className="news_img">
                      <img
                        src={`https://admin.photonauto.ru/public/image/${item.news_images}`}
                        className={"img-fluid"}
                        alt=""
                      />
                    </div>
                    <div className="news_text">
                      <h3>{item[`title_${intl.locale}`]}</h3>
                      {/* <p>{item[`description_${intl.locale}`]}</p> */}
                    </div>
                    <div className={"prev-link-div"}>
                      <Link to={`/NewsList/${item.id}`} className={"prev-link"}>
                        <FormattedMessage id={"see_more"} />
                      </Link>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Container>
    </>
  );
}
export default News;
