import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Wrapper from "./Pages/Multilang/Wrapper";

ReactDOM.render(
  <Wrapper>
    <App />
  </Wrapper>,
  document.getElementById("root")
);
